import { authRoutes } from '@/modules/auth/mock/authRoutes.js'
import { catalogRoutes } from '@/modules/catalog/mock/catalogRoutes.js'
import { diseasesControlRoutes } from '@/modules/diseasesControl/mock/diseasesControlRoutes'
import { farmRoutes } from '@/services/__mocks__/farmRoutes'
import { fieldRoutes } from '@/modules/plot/mock/fieldRoutes.js'
import { menuRoutes } from '@/modules/menu/mock/menuRoutes.js'
import { noteRoutes } from '@/modules/notes/mock/noteRoutes.js'
import { oldSowingRoutes } from '@/modules/oldSowing/mock/oldSowingRoutes.js'
import { paymentRoutes } from '@/modules/payment/mock/paymentRoutes.js'
import { seasonCropsRoutes } from '@/services/__mocks__/seasonCropsRoutes.js'
import { sowingsPlanningRoutes } from '@/modules/sowingsPlanning/mock/sowingsPlanningRoutes.js'
import { sowingsListRoutes } from '@/modules/sowingsList/mock/sowingsListRoutes.js'
import { sowingViewRoutes } from '@/modules/sowingView/mock/sowingViewRoutes.js'
import { varietyRoutes } from '@/pages/cultivares/mock/varietyRoutes.js'

const routes = [
  ...authRoutes,
  ...catalogRoutes,
  ...diseasesControlRoutes,
  ...farmRoutes,
  ...fieldRoutes,
  ...menuRoutes,
  ...noteRoutes,
  ...oldSowingRoutes,
  ...paymentRoutes,
  ...seasonCropsRoutes,
  ...sowingsPlanningRoutes,
  ...sowingsListRoutes,
  ...sowingViewRoutes,
  ...varietyRoutes,
]

function globalAPIFailureWrapper(content, failureRate = 0) {
  const chance = Math.floor(Math.random() * 100)
  if (failureRate > chance) {
    throw new Error('Falha por conta causa do wrapper global do mock')
  }
  return content
}

export const mockServer = {
  environment: 'production',

  models: {},

  routes() {
    const checkIfSandbox = process.env.VUE_APP_API_URL.includes('sandbox')
    const getCurrentEnvironmentUrl = checkIfSandbox
      ? 'https://sandbox-api.digifarmz.com/web-api'
      : 'http://localhost'
    this.urlPrefix = getCurrentEnvironmentUrl
    this.namespace = 'api'
    this.passthrough('https://maps.googleapis.com/**')
    this.passthrough('https://viacep.com.br/**')
    this.passthrough(`${process.env.VUE_APP_VINDI_URL}/**`)
    this.passthrough()

    routes.forEach((route) => {
      this[route.method](route.url, (_, request) =>
        globalAPIFailureWrapper(route.result(request), 0)
      )
    })
  },

  seeds() {},
}
